import React, { createContext, Suspense, useEffect, useReducer } from "react";
import Immutable from "immutable";
import { matchPath, useHistory } from "react-router";
import { loginQuery, randomString } from '../utils/resturl';
import request from '../utils/request'
import tpaths from '../jspathroute/index'
const tokenName = 'fcjx_login_token'
const Main = React.lazy(() => import("../main"));
const Login = React.lazy(() => import('../pages/customerManagement/login'))

let initialState = {
	loginning: 0,
	login: {},
	logined: 0,
	tabs: [],
	activeTab: '',
	eventType: '',
	eventValue: '',
	printTabs: [],
};
const loginReducer = (state, action) => {
	switch (action.type) {
		case "refreshEvent":
			state.eventType = action.payload.eventType;
			state.eventValue = randomString(15);
			return Immutable.fromJS(state).toJS();
		case "loginning":
			state.loginning = 1;
			return Immutable.fromJS(state).toJS();
		case "login":
			state.logined = 1;
			state.login = action.payload.login;
			console.log('//////////////', state.login)
			if (!!state.login && !!state.login.token) {
				sessionStorage.setItem(tokenName, state.login.token)
			}
			return Immutable.fromJS(state).toJS();
		case "logout":
			state.logined = 0;
			state.login = {};
			sessionStorage.clear('tokenName');
			return Immutable.fromJS(state).toJS();
		case 'print_tab':
			{
				let tab1 = action.payload.tab;
				let tflg = false;
				(!!state.printTabs) && state.printTabs.forEach(it => {
					if (it.url === tab1.url) {
						tflg = true;
						if (!!action.payload && !!action.payload.params) {
							it.state = { ...action.payload.params }
						}
					}
				});
				if (!tflg) {
					let route = null;
					if (!!tpaths) {
						tpaths.forEach(it => {
							const result = matchPath(tab1.url, {
								path: it.path,
								exact: true,
							});

							if (!!result) {
								route = JSON.parse(JSON.stringify(it));
								route.match = {};
								route.match.params = JSON.parse(JSON.stringify(result.params))
								if (!!action.payload && !!action.payload.params) {
									route.state = { ...action.payload.params }
								}
							}
						})
					}
					if (!!route) {
						if (!!tab1.title)
							route.title = tab1.title;
						route.url = tab1.url;
						if (!state.printTabs) {
							state.printTabs = [];
						}
						state.printTabs.push({ ...route, active: true });
					}
				}
				console.log('the login provider print tabs is::', state.printTabs)
				state.printTabs = [...(state.printTabs || [])]
				return Immutable.fromJS(state).toJS();
			}
		case 'print_end': {
			let url11 = action.payload.url;
			let tindex11 = -1;
			// let ttab=null;
			(!!state.printTabs) && state.printTabs.forEach((it, index) => {
				if (it.url === url11) {
					tindex11 = index;
					// ttab=it;
					return false;
				}
			})
			if (tindex11 == -1) {
				return Immutable.fromJS(state).toJS();
			}
			if (tindex11 >= 0) {
				state.printTabs.splice(tindex11, 1)
			}
			console.log('准备关闭 print tabs is::', state.printTabs)
			state.printTabs = [...(state.printTabs || [])]
			return Immutable.fromJS(state).toJS();
		}
		case "add_new_tab":
			let tab = action.payload.tab;
			let tflg = false;
			(!!state.tabs) && state.tabs.forEach(it => {
				if (it.url === tab.url) {
					tflg = true;
					it.active = true;
					state.activeTab = tab.url;
					if (!!action.payload && !!action.payload.params) {
						it.state = { ...action.payload.params }
					}
					// if(!history){
					// 	history.push(tab.url);
					// }
				} else {
					it.active = false;
				}
			})
			if (!tflg) {
				let route = null;
				if (!!tpaths) {
					tpaths.forEach(it => {
						const result = matchPath(tab.url, {
							path: it.path,
							exact: true,
						});
						if (!!result) {
							route = JSON.parse(JSON.stringify(it));
							route.match = {};
							route.match.params = JSON.parse(JSON.stringify(result.params))
							if (!!action.payload && !!action.payload.params) {
								route.state = { ...action.payload.params }
							}
						}
					})
				}
				if (!!route) {
					if (!!tab.title)
						route.title = tab.title;
					route.url = tab.url;
					if (!state.tabs) {
						state.tabs = [];
					}
					state.tabs.push({ ...route, active: true });
					state.activeTab = tab.url;
					console.log('找到route', state.tabs)
					// if(!history){
					// 	history.push(tab.url);
					// }
				}
			}
			saveTabsSessionstorage(state.tabs, state.activeTab);
			return Immutable.fromJS(state).toJS();
		case "active_new_tab":
			{
				console.log('the login provider is::', 'active_new_tab',)
				let ttturl = action.payload.url;
				let ss = state.tabs.filter(ii => ii.url == ttturl);
				if (!!ss && ss.length > 0) {
					state.activeTab = ttturl;
				}
				console.log('the login provider is::', 'active_new_tab', ss, ttturl)
				return Immutable.fromJS(state).toJS();
			}
		case "close_tab":
			let url = action.payload.url;
			let tindex = -1;
			// let ttab=null;
			(!!state.tabs) && state.tabs.forEach((it, index) => {
				if (it.url === url) {
					tindex = index;
					// ttab=it;
					return false;
				}
			})
			if (tindex == -1) {
				return Immutable.fromJS(state).toJS();
			}
			if (tindex >= 0) {
				// state.tabs=
				state.tabs.splice(tindex, 1)
				console.log('找到要删除的没有', tindex, state.tabs)
				if (state.tabs.length > tindex) {
					state.tabs[tindex].active = true;
					state.activeTab = state.tabs[tindex].url;
				} else if (state.tabs.length > 0) {
					state.tabs[state.tabs.length - 1].active = true;
					state.activeTab = state.tabs[state.tabs.length - 1].url;
				} else {
					state.activeTab = '';
				}
			}
			saveTabsSessionstorage(state.tabs, state.activeTab);
			return Immutable.fromJS(state).toJS();
		case "close_tab_m":
			if (!!state.tabs) {
				let tttabs = state.tabs.filter(it => !!matchPath(it.url, {
					path: action.payload.path,
					exact: true,
				}));
				state.tabs = tttabs;
			}
			saveTabsSessionstorage(state.tabs, state.activeTab);
			return Immutable.fromJS(state).toJS();
		case "init_tabs":   //当用户刷新页面的时候，需要从sessionstorage里面获取tabs，因此上面需要将tabs存入sessionstorage里面，
			state.tabs = action.payload.tabs;
			state.activeTab = action.payload.activeTab;
			return Immutable.fromJS(state).toJS();
		case "context_menu_close":
			let { paneKey, indx, closeType } = action.payload;
			//以下逻辑暂时未实现
			switch (closeType) {
				//关闭其他
				case "closeOthers": {
					let ss = state.tabs.filter(ii => ii.url == paneKey);
					state.tabs = ss;
					state.activeTab = paneKey;
					break;
				}
				//关闭左侧
				case "closeLefts": {
					let tindex = -1;
					state.tabs.forEach((iii, ttindex) => {
						if (iii.url == paneKey) {
							tindex = ttindex;
							return false;
						}
					})
					if (tindex == -1) {
						console.log('login provider close lefts is -1::');
						return Immutable.fromJS(state).toJS();
					} else {
						let ss = state.tabs.slice(tindex);
						console.log('login provider close lefts is not -1::', tindex, ss)
						state.tabs = ss;
						state.activeTab = paneKey;
					}
					break;
				}
				//关闭右侧
				case "closeRights": {
					let tindex = -1;
					state.tabs.forEach((iii, ttindex) => {
						if (iii.url == paneKey) {
							tindex = ttindex;
							return false;
						}
					})
					if ((tindex + 1) == state.tabs.length) {
						console.log('login provider close rights is  -1::', tindex)
						return Immutable.fromJS(state).toJS();
					} else {
						let ss = state.tabs.slice(0, tindex + 1);
						console.log('login provider close Rights is not -1::', tindex, ss)
						state.tabs = ss;
						state.activeTab = paneKey;
					}
					break;
				}
				//关闭全部
				case "closeAll": {
					console.log('login provider close closeAll is  ::',)
					state.tabs = [];
					state.activeTab = '';
					break;
				}
				default:
					break;
			}
			return Immutable.fromJS(state).toJS();
		default:
			console.log("login reducer defuat");
			return Immutable.fromJS(state).toJS();
	}
};
const LoginContext = createContext();

const saveTabsSessionstorage = async (tabs, activeTab) => {
	if (tabs == null || tabs.length <= 0) {
		return;
	}
	let tobj = { tabs: tabs, activeTab: activeTab };
	await sessionStorage.setItem("fcjx_tabs", JSON.stringify(tobj));
}

const readTabsSessionstorage = async () => {
	let tstr = await sessionStorage.getItem("fcjx_tabs");
	if (!!tstr) {
		let tobj = JSON.parse(tstr);
		return tobj;
	}
	return null;
}

const LoginProvider = (props) => {
	const [loginState, loginDispatch] = useReducer(loginReducer, initialState);
	const history = useHistory();
	useEffect(() => {
		console.log("loginstate is:::", loginState)
		let login_token = sessionStorage.getItem(tokenName);
		if (!!login_token) {
			request(loginQuery, { body: { loginToken: login_token } }).then(res => {
				if (res.code == 0) {
					loginDispatch({ type: 'login', payload: { login: res.item } });
				}
				// loginDispatch({ type: 'login', payload: { login: { username: '辛弃疾' } } });
			});
			let tobj = readTabsSessionstorage();
			if (!!tobj) {
				loginDispatch({ type: 'init_tabs', payload: { tabs: tobj.tabs, activeTab: tobj.activeTab } });
			}
		}
		console.log("loginstate is:::", loginState)
	}, [history]);
	return (
		<LoginContext.Provider value={{ state: loginState, dispatch: loginDispatch }}>
			<Suspense fallback={<div>正在加载中......</div>}>
				{props.children}
				{/* <Main /> */}
				{
					loginState.logined === 0 ? <Login dispatch /> : <Main />
				}
			</Suspense>
		</LoginContext.Provider>
	);
};
export { LoginProvider, LoginContext };
