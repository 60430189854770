export const randomString = (length) => {
    var str = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
        result += str[Math.floor(Math.random() * str.length)];
    return result;
};
//判断文件格式是不是图片
export const isAssetTypeAnImage = (ext) => {
    let ff = ['.png', '.jpg', '.jpeg', '.bmp', '.gif', '.webp', '.psd', '.svg', '.tiff']
    let i = -1;
    ff.forEach(element => {
        if (ext.indexOf(element) != -1)
            return i = 1;
    });
    return i > -1
}
export const questionTreeList = (list) => {
    let treeData = [];
    list.forEach(element => {
        // element.key = element.id
        if (!element.pid || element.pid == 0) {
            treeData.push(element);
        } else {
            list.forEach(ele1 => {
                if (element.pid === ele1.id) {
                    if (ele1.children == null) {
                        ele1.children = [];
                    }
                    ele1.children.push(element);
                }
            })
        }
    })
    return treeData;
}
export const questionSelectTree = (list) => {

    let treeData = [];
    let hasName={};
    list.forEach(element => {
        element.title = element.name
        element.value = element.id
        element.key = element.id
        if (!element.pid || element.pid == 0) {
            treeData.push(element);
            hasName[element.id]=element.name
            hasName[element.name]=element.id
        } else {
            list.forEach(ele1 => {
                if (element.pid === ele1.id) {
                    if(hasName[element.name]){
                        hasName[element.id]= hasName[element.pid]+'-'+element.name;
                        hasName[element.name]= element.id;
                    }else{
                        hasName[element.id]=element.name
                        hasName[element.name]=element.id
                    }
                    if (ele1.children == null) {
                        ele1.children = [];
                    }
                    element.name=hasName[element.id]
                    element.title=hasName[element.id]
                    ele1.children.push(element);
                }
            })
        }
    })
    return treeData;
}

export const updateTreeData = (tree, id, children) => {
    if (!tree || tree.length <= 0) {
        return [...children];
    }
    return tree.map(node => {
        if (node.id == id) {
            return {
                ...node,
                children
            };
        }
        if (!!node.children) {
            return {
                ...node,
                children: updateTreeData(node.children, id, children)
            }
        }
        return { ...node };
    })
}

export const toFixed = (num, lng) => {
    if (isNaN(num))
        return '';
    let lgth = lng ? lng : 2;
    if (!!!num || num === '') {
        return num;
    }
    if (num.toString().split('.')[1]) {
        if ((num.toString().split('.')[1]).length > lgth) {
            return Number(num.toString()).toFixed(lgth)
        } else {
            return Number(num.toString())
        }
    } else {
        return num;
    }
}
//去除首尾空格
export const trimSpace=(str)=>{
    if (str == 0) {
        return str + ''
    }
    if (str) {
        str = str + ''
        return str.replace(/^\s+|\s+$/g,"")
    }
    return null;
}


export const uploadMaxSize = 5//上传文件最大限制值

export const imgHost = '/serverimg'
export const downloadHost = '/serverdownload'
// export const imgHost = 'http://192.168.1.82:9000'
// export const imgHost = 'http://39.105.22.214:9000'
// export const downloadHost = 'http://192.168.1.66:3000/serverdownload/'