let antiRepeatToken = ''
let lastAntiRepeatToken = '';//上一次的值


export const getAntiRepeat = () => {
  return { antiRepeatToken: antiRepeatToken, lastAntiRepeatToken: lastAntiRepeatToken };
}
export const saveAntiRepeat = (obj) => {
  Object.keys(obj).forEach(e => {
    if (e == 'antiRepeatToken') {
      antiRepeatToken = obj[e]
    } else if (e == 'lastAntiRepeatToken') {
      lastAntiRepeatToken = obj[e]
    }
  });
}