import { getAntiRepeat, saveAntiRepeat } from "./clientToken";
import { trimSpace } from "./util";

let proxy = '/api';
// let antiRepeat = null;

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        let headers = response.headers;
        if (!!headers) {
            if (!!headers) {
                let antObj = getAntiRepeat();
                if (headers.get('antiRepeat') && headers.get('antiRepeat') != antObj.antiRepeatToken && headers.get('antiRepeat') != antObj.lastAntiRepeatToken) {
                    console.log('...checkStatus..headers.....', headers.get('antiRepeat'), '........headers>>>>>>>>')
                    saveAntiRepeat({ antiRepeatToken: headers.get('antiRepeat') })
                }
            }
            // if (headers.get('antiRepeat')) {
            //     antiRepeat = headers.get('antiRepeat')
            // }
        }
        return response.json();
    } else {
        return new Promise((resolve) => resolve({ msg: '没有正常返回', code: 10 }));
    }
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(url, options) {
    // debugger
    const defaultOptions = {
        credentials: 'include',
    };
    let newOptions = { ...defaultOptions, ...(options || {}), method: (options || {}).method || 'POST' };
    if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
        newOptions.headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            ...newOptions.headers,
        };
        // if (!!antiRepeat) {
        //     newOptions.headers.antiRepeat = antiRepeat;
        // }
        let antObj = getAntiRepeat();
        if (!!antObj.antiRepeatToken) {
            newOptions.headers.antiRepeat = antObj.antiRepeatToken;
            saveAntiRepeat({ lastAntiRepeatToken: antObj.antiRepeatToken })
        }

        //body里面的参数去空格
        if (newOptions.body) {
            if (newOptions.body['condition']) {
                newOptions.body['condition'] = trimSpace(newOptions.body['condition'])
            }
        }

        newOptions.body = JSON.stringify(newOptions.body)
    }
    const path = proxy + url;
    return fetch(path, newOptions)
        .then(checkStatus)
        .then(json => {
            if (json.code == 2) {
                window.open("/work/potal/SignIn");
                throw new Error('需要登录');
            }
            return new Promise((resolve) => resolve(json));
        })
        .catch((error) => {
            console.log("ajax is error!", error);
            return { msg: '接口：' + url + '没有返回值,请修改!', code: 10 };
        });
}
export default request