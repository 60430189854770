import React, { useContext, useState } from 'react';
import {ConfigProvider,Radio} from 'antd';
import logo from './logo.svg';
import "antd/dist/antd.css";
import { LoginProvider} from './providers/loginProvider'
import zhCN from 'antd/lib/locale/zh_CN';

function App() {
  //发现有一些组件为英文，这里对国际化语言进行设置，默认为中文，
  const [locale,setLocale] = useState(zhCN)
  // const changeLocale = e => {
  //   const localeValue = e.target.value;
  //   setLocale(localeValue);
  // };
  return (
    <div className="App">
      <ConfigProvider locale={locale}>
        <LoginProvider key={locale ? locale.locale : 'en'}/>
      </ConfigProvider>
    </div>
  );
}

export default App;
