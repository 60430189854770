export default [
    {
        path: "/pages/customerManagement/homePage",
        title: '首页',
    },
    {
        path: '/pages/customerManagement/login',
        title: '登录',
    },
    {
        path: "/pages/customerManagement/userManagement/userActionLog",
        title: '用户操作日志',
    },
    {
        path: "/pages/customerManagement/userManagement/systemLog",
        title: '系统日志',
    },
    {
        path: "/pages/customerManagement/enterpriseUsers/userList",
        title: '企业管理',
    },
    {
        path: "/pages/customerManagement/softwareVersion",
        title: '版本购买记录',
    },
    {
        path: "/pages/customerManagement/individualUser/individualUser",
        title: '个人用户',
    },
    {
        path: "/pages/customerManagement/auditRecord/enterpriseCertificationAudit",
        title: '企业认证',
    },
    {
        path: "/pages/customerManagement/personalAuthentication/record",
        title: '个人认证',
    },
    {
        path: "/pages/customerManagement/userAnnouncementManagement/userAnnouncement",
        title: '企业公告',
    },
    {
        path: "/pages/customerManagement/userEvaluationManagement/administration",
        title: '评价记录',
    },
    {
        path: "/pages/customerManagement/purchaseRecord/purchaseRecord",
        title: '版本购买记录',
    },
    {
        path: '/pages/customerManagement/enterprisePromotion/enterprisePromotion',
        title: '企业推广',
    },
    {
        path: "/pages/customerManagement/productManagement/classiFication",
        title: '行业分类',
    },
    {
        path: "/pages/customerManagement/productManagement/Brand",
        title: '品牌管理',
    },
    {
        path: "/pages/customerManagement/productManagement/category",
        title: '品类管理',
    },
    {
        path: "/pages/customerManagement/productManagement/productList",
        title: '产品列表',
    },
    {
        path: "/pages/customerManagement/productManagement/appealProducts",
        title: '产品经销申诉',
    },
    {
        path: "/pages/customerManagement/productManagement/addAppealProducts",
        title: '新增产品经销申诉',
    },
    {
        path: "/pages/customerManagement/productManagement/appealProductsDetails",
        title: '产品经销申诉详情',
    },
    {
        path: "/pages/customermanagement/productmanagement/dealupdateapply",
        title: '产品总库修改申请处理',
    },
    {
        path: "/pages/customermanagement/productmanagement/dealapply/:id/details",
        title: '处理详情',
    },
    {
        path: "/pages/customerManagement/roleManagement/roleAssignment",
        title: '权限分配',
    },
    {
        path: "/pages/customerManagement/adminRegion/administrativeRegion",
        title: '行政区',
    },
    {
        path: "/pages/customerManagement/systemSettings/systemSettings",
        title: '系统设置',
    },
    {
        path: "/pages/customerManagement/Organization/organizationTemplate",
        title: '职位模板',
    },
    {
        path: "/pages/customerManagement/userManagement/userActionLog",
        title: '操作日志',
    },
    {
        path: "/pages/customerManagement/userManagement/jobLog",
        title: '定时任务日志',
    },
    {
        path: "/pages/customerManagement/homepageManagement/bannerManagement",
        title: 'banner管理',
    },
    {
        path: "/pages/businessDistrict/businessDistrict",
        title: '商圈列表',
    },
    {
        path: "/pages/businessDistrict/merchantInformationManagement",
        title: '企业信息管理',
    },
    {
        path: "/pages/complaintManagement/complaintManagement",
        title: '投诉管理',
    },
    {
        path: "/pages/customerManagement/enterpriseUsers/newUser",
        title: '新增企业用户',
    },

    {
        path: "/pages/customerManagement/individualUser/accountDetails",
        title: '个人用户账户明细',
    },
    {
        path: "/pages/customerManagement/individualUser/editIndividualUser",
        title: '编辑个人用户',
    },
    {
        path: "/pages/customerManagement/individualUser/newIndividualUser",
        title: '新增个人用户',
    },
    {
        path: "/pages/customerManagement/purchaseRecord/newPurchaseInformation",
        title: '新增版本购买记录',
    },
    {
        path: "/pages/customerManagement/enterpriseUsers/editUserInformation",
        title: '编辑企业用户',
    },
    {
        path: "/pages/customerManagement/purchaseRecord/modifyPurchaseInformation",
        title: '修改版本购买记录',
    },
    {
        path: "/pages/customerManagement/productManagement/:id/newProducts",
        title: '新增产品',
    },
    {
        path: "/pages/customerManagement/productManagement/productDetails",
        title: '产品详情',
    },
    {
        path: "/pages/customerManagement/productManagement/:id/modify",
        title: '修改产品',
    },
    {
        path: "/pages/customerManagement/Organization/permissionAssignment",
        title: '权限分配',
    },
    {
        path: "/pages/customerManagement/Organization/NewOrganization",
        title: '新增职位模板',
    },
    {
        path: "/pages/customerManagement/Organization/modifyOrganization",
        title: '修改职位模板',
    },
    {
        path: "/pages/customerManagement/homepageManagement/notice/notice",
        title: '公告管理',
    },
    {
        path: "/pages/customerManagement/homepageManagement/real-timeInfo/realTimeInfo",
        title: '资讯管理',
    },
    {
        path: "/pages/businessDistrict/:id/regionalBusinessDetails",
        title: '商圈详情',
    },
    {
        path: "/pages/businessDistrict/historicalElimination",
        title: '历史踢除',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/system",
        title: '版本管理',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/versionView",
        title: '版本查看',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/systemJurisdiction",
        title: '配置权限',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/list",
        title: '账号管理',
    },
    {
        path: "/pages/customerManagement/purchaseRecord/logisticsPoint",
        title: '物流点管理',
    },
    {
        path: "/pages/customerManagement/purchaseRecord/newLogisticsPoint",
        title: '新增物流点管理',
    },
    {
        path: "/pages/customerManagement/purchaseRecord/editLogisticsPoint",
        title: '编辑物流点管理',
    },
    {
        path: "/pages/businessDistrict/productAppeal",
        title: '商圈产品申诉',
    },
    {
        path: "/pages/businessDistrict/localDealers",
        title: '本地经销商',
    },
    {
        path: "/pages/businessDistrict/recruit",
        title: '招聘管理',
    },
    {
        path: "/pages/businessDistrict/attractInvestment",
        title: '招商管理',
    },
    {
        path: "/pages/customerManagement/Organization/permissionAssignment",
        title: '分配角色',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/role",
        title: '角色管理',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/menu",
        title: '菜单管理',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/jurisdiction",
        title: '权限分配',
    },
    {
        path: "/pages/customerManagement/operationAndMaintenance/personnelList",
        title: '系统管理员',
    },
    {
        path: "/pages/customerManagement/individualAccount/individualAccount",
        title: '个人账户',
    },
    {
        path: "/pages/customerManagement/individualAccount/IndividualUser",
        title: '个人用户账户明细',
    },
    {
        path: "/pages/customerManagement/individualAccount/enterpriseAccount",
        title: '企业账户',
    },
    {
        path: "/pages/customerManagement/individualAccount/enterpriseUsers",
        title: '企业用户账户明细',
    },
]
